// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {FacilityAccessibilityProduct} from "../product.facility/model/FacilityAccessibilityProduct";
import {EProductType} from "../javascript.lib.mojo-base/product.common/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  filename: 'environment.facilities-4c4e4b44.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-4c4e4b44.cloudfunctions.net',

  firebaseConfig: {
    apiKey: "AIzaSyCdl8yj_gClPfVxtHU-yJN-WUPaKKHVApU",
    appId: "1:1016229382425:web:30e8183731a5e5c712dd88",
    authDomain: "facilities-4c4e4b44.firebaseapp.com",
    databaseURL: "https://facilities-4c4e4b44-default-rtdb.europe-west1.firebasedatabase.app",
    messagingSenderId: "1016229382425",
    projectId: "facilities-4c4e4b44",
    storageBucket: "facilities-4c4e4b44.appspot.com",
  },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    productType: EProductType.facilities,
  },

  // product: new FacilityAccessibilityProduct(),
  product:  FacilityAccessibilityProduct4.INSTANCE,

  supportsSubmitButton: true,

};
